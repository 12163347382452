@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --sidebar-background: 210 71% 25%;
    --sidebar-foreground: 0 0% 100%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
  .dark {
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

@layer base {
  body {
    @apply !font-montserrat antialiased tracking-wide text-gray-900;
  }
}

@layer utilities {
  .country-list .search-box {
    @apply bg-slate-200 ring-1 ring-slate-400 focus:!ring-primary-500;
  }
}

@keyframes progress {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.progress-bar-animation {
  width: 100%;
  animation: progress 2s linear infinite alternate;
}

.card-number-container input {
  height: 3rem;
  width: 100%;
}

iframe {
  width: 100%;
  max-height: 100%;
}

.react-tel-input .country-list .search {
  @apply flex !px-2 items-center;
}

.react-tel-input .country-list .search-emoji {
  @apply hidden;
}
.react-tel-input .country-list .search-box {
  @apply bg-slate-200 ring-1 ring-slate-400 focus:!ring-primary-500 flex-grow;
}

.card-number-container iframe {
  @apply w-[220%];
}

a.active{
  @apply bg-white text-primary-500;
}