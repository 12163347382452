$darkBlueColor: #010863;
$blueColor: #2630B6;
$lightBlueColor: #128DFF;
$errorColor: #FF6B6B;
$backgroundColor:#ECEEF5;
$greyColor: #4B4B4B;
$ligthgreyColor: #a29d9d;
$greenColor:#02A530;
$secondaryColor: white;

$fontWeightThin: 100;
$fontWeightExtraLight: 200;
$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightSemiBold: 600;
$fontWeightBold: 700;
$fontWeightExtraBold: 800;
$fontWeightBlack: 900;

$fontSizeExtraSmall: 10px;
$fontSizeSmall: 13px;
$fontSizeMedium: 15px;
$fontSizeLarge: 20px;
$fontSizeExtraLarge: 25px;
$fontSizeExtraLargeL: 28px;

$margin-small: 1rem;
$margin-medium: 2rem;
$margin-high : 4rem;

$padding-small : 1rem;

$breakpoints: (
  cellPhone: 540px,
  tablet: 900px,
  tablet15: 1300px,
);

$standar-border-radius: 5px;

@mixin media-up($breakpoint) {
  @media screen and (max-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}