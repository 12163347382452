@import "./globalStyles";

body {
  margin: 0;
  font-family: 'Roboto', sans-serif, 'Work Sans', 'Helvetica Neue',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $backgroundColor;
}

.primaryButton{
  color:$secondaryColor;
  background-color: $blueColor;
  border-color: transparent;
  border-radius: $standar-border-radius;
  
  &:hover {
      color: $secondaryColor;
      background-color: #086edb;
      border-color: transparent;
      border-radius: $standar-border-radius;
  }

  &:disabled {
    background-color: #ccc;
    border: 2px solid #ccc;
    cursor: not-allowed;
  }
}

.greyButton{
  height: 37px;
  width: 30%;   
  color: grey;
  border: none;
  background-color: transparent;
  &:hover {
      background-color: #4b4b4b73;
      color: $secondaryColor;
      border-radius: $standar-border-radius;
  }
  &:disabled {
    background-color: #ccc;
    border: 2px solid #ccc;
    cursor: not-allowed;
    border-radius: $standar-border-radius;
  }
}

.card-on-hover{
  &:hover {
    background-color: $secondaryColor;
    border: 0.1em solid $blueColor;
    transform: scale(1.03); 
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); 
  }
}

.card-basic-style{
  transition: all 0.5s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color:$secondaryColor;
  border-radius: 0.50rem;
  color: black;
  border: none;
}

.basic-scroll{
  overflow-y: scroll;

  &::-webkit-scrollbar {
      width: 6px; 
    }  
  &::-webkit-scrollbar-thumb {
  background: $ligthgreyColor;
  }
}

.no-scroll{
  overflow-y: hidden;
}

.basic-scroll-hide{
  overflow-y: scroll;

  &::-webkit-scrollbar {
      width: 0px; 
    }  
  &::-webkit-scrollbar-thumb {
  background: $ligthgreyColor;
  }
}

.basic-scroll-y-hide{
  overflow-y: scroll;
  overflow-x: none;
  
  &::-webkit-scrollbar {
      width: 0px; 
    }  
  &::-webkit-scrollbar-thumb {
  background: $ligthgreyColor;
  }
}

.generalTitles{
  font-weight: $fontWeightRegular;
  font-size: $fontSizeExtraLarge;
}

.small-titles{
  font-weight: $fontWeightRegular;
  font-size: $fontSizeExtraLarge;
  @include media-up(tablet15) {
    font-weight: $fontWeightRegular;
    font-size: $fontSizeMedium;
    font-weight: 700;
  }
}

.signUp-input-titles-text{
  font-weight: $fontWeightSemiBold;
  font-size: $fontSizeSmall;
}

.visibilityHidden{
  visibility: hidden
}

.align-center{
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.text-center{
  text-align:center;
}

.flex-column{
  display: flex;
  flex-direction: column;
}

.display-flex-cel-block{
    display:flex;
  @include media-up(cellPhone) {
    display:block;
  } 
}

.text-end{
  text-align: end;
}

.cellphone-display-none{
  @include media-up(cellPhone) {
    display: none !important;
  }
}

.computer-display-none{
  display: none ;
}

.modal-content{
  min-height:250px;
  background-color: $darkBlueColor;
  color: $secondaryColor ;
  text-align: center;
  @include media-up(cellPhone) {
    max-height: 300px;    
  }
}

.modalButton{
  border: none;
  background-color: transparent;
}

.smodal-body{
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  padding: $padding-small;
  justify-content: center;
  align-items: center;
}

.stext-white-space{
  white-space: pre-line;
  line-height :1.2
}

.small-text{
  font-weight: $fontWeightRegular;
  font-size: $fontSizeSmall;
}

.text-blue{
  font-weight: $fontWeightMedium;
  font-size: $fontSizeExtraLarge;
  color: $lightBlueColor;
  @include media-up(tablet15) 
  {
    font-weight: $fontWeightMedium;
    font-size: $fontSizeLarge; 
  }
  @include media-up(cellPhone) 
  {
    font-weight: $fontWeightRegular;
    font-size: $fontSizeMedium;  
  }
}

.text-gold{
  font-weight: $fontWeightSemiBold;
  font-size: $fontSizeLarge; 
  color: gold;
  @include media-up(tablet15) 
  {
    font-weight: $fontWeightMedium;
    font-size: $fontSizeMedium; 
  }
  @include media-up(cellPhone) 
  {
    font-weight: $fontWeightMedium;
    font-size: $fontSizeSmall; 
  }
}


.modal-footer{
  border: none;
}

.smodal-footer{
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.w-150px{
  width: 150px;
}

.w-30{
  width: 30%;
}

.w-35{
  width: 35%;
}

.w-fit-35{
  min-width:fit-content;
  width: 35%;
}

.w-100{
  width: 100%;
}

.w-8rem{
  width: 8rem;
}

.h-1r{
  height: 1rem;
}

.h-37{
  height: 37px;
}

.h-100{
  height: 100%;
}

.h-10{
  height: 10%;
}

.pt-1rem{
  padding-top: 1rem
}

.p-0{
  padding: 0;
}

.m-0{
  margin: 0;
}

.mh-100{
  max-height: 100%;
}

.border-top{
  border-top: 1px solid #00000047;
  }
  
.form-control{
  max-width: 90%;
}

.basic-font{
  font-size: $fontSizeSmall;
  font-weight: $fontWeightMedium; 
}

.link-td {
  color: blue; 
  cursor: pointer; 
  transition: color 0.2s; 
  &:hover {
      color: purple;
  }
}   